import React from "react"
import Marquee from "../components/marquee"
import Layout from "../components/layout"
import "../styles/index.scss"
import styles from "../styles/accueil.module.scss"
import Footer from "../components/footer"
import mcgill from "../Images/mcgill.png"
import quebec from "../Images/quebec.png"
import carre from "../Images/autobus-carre.png"
import { Link } from "gatsby"
import doctoctoc from "../Images/DocTocToc_logos/DocTocToc_logo_couleur.svg"
import pinIcon from "../Images/SVG_web_illustrations/DTT_Icone_Localisation.svg"
import pngBus from "../Images/SVG_web_illustrations/DTT_Autobus.png"
import oeil from "../Images/SVG_web_illustrations/DTT_Oeil.svg"
import gears from "../Images/SVG_web_illustrations/DTT_Roues.svg"
import rislaine from "../Images/rislaine.png"
import couture from "../Images/couture.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "../hooks/useTranslation"
import { useIntl } from "react-intl"

const HomePage = () => {

    const windowLang = () => {
        if (typeof window !== `undefined`) {
            return window
        }
    }

    const t = useTranslation();
    const intl = useIntl();

    return (
        <Layout>
            <div className={styles.body}>

                <div className={styles.header_section}>
                    <div className={styles.purple_bubble_holder}>
                        <div className={styles.second_purple_bubble}>
                            <p>{t("accueil.healthcareAccess")}</p>
                            <span><a href="https://app.simplyk.io/fr/donation-form/6ae5b923-05d8-471b-a093-f9636a887bc2" target="_blank">{t("accueil.here")}</a></span>
                        </div>
                        <div className={styles.purple_bubble}>
                            <p>{t("accueil.qui")}</p>
                        </div>
                    </div>
                    <div className={styles.blue_bubble_holder}>
                        <div className={styles.blue_bubble}>
                            <p>TocTocToc</p>
                        </div>
                        <div className={styles.second_blue_bubble}>
                            <p>DocTocToc</p>
                        </div>
                    </div>
                    <img src={pngBus} className={styles.bus_img} alt="bus doctoctoc" />
                </div>
                
                <div className={styles.logo_section}>
                    <img src={mcgill}/>
                    {/* <img src={quebec}/> */}
                </div> 

                <div className={styles.mission_section}>
                    <div className={styles.logo_holder}>
                        <div className={styles.text}>
                            <svg viewBox="0 0 400 400" className={styles.textRotation}>
                                <path id="curve" d="M180,430
                                    a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                                    transform="rotate(70,250,250)" fill="transparent" />
                                <text width="500" dy="50">
                                    <textPath {...{ xlinkHref: "#curve" }} textLength="900" style={{ fontSize: "40px" }}>
                                        {t("accueil.mission")}
                                    </textPath>
                                </text>
                            </svg>
                        </div>
                        <div className={styles.img_holder}><img src={carre} alt="logo" /></div>
                    </div>

                    <div className={styles.mission_description}>
                        <img src={doctoctoc} className={styles.logo}/>
                        {t("accueil.description.est")}
                        {t("accueil.description.sans")}
                        {t("accueil.description.lucratif")}
                        <span className={styles.orange}>{t("accueil.description.offir")} </span>
                        <span className={styles.orange}>{t("accueil.description.medicaux")} </span>
                        <span className={styles.orange}>{t("accueil.description.vulnerables")}</span>
                        {t("accueil.description.pas")}
                        {t("accueil.description.acces")}
                        {t("accueil.description.services")}
                        <FontAwesomeIcon color="#f04e23" icon={faHeart} size="1x" />.
                    </div>
                </div>

                {/* <div className={styles.popup_clinic}>
                    <p>{t("accueil.findUs")} <span  className={styles.pop}>pop</span><span className={styles.up}>-up</span></p>
                    <p><span className={styles.orange}>{t("accueil.findUsDate")}</span> {t("accueil.findUsTime")}</p>
                    <a href="https://www.google.com/maps/place/45%C2%B037'01.9%22N+73%C2%B037'16.9%22W/@45.6171944,-73.6219083,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d45.6171971!4d-73.6213612" target="_blank">
                        <div className={styles.pin}>
                            <img src={pinIcon} alt="pin"/>
                        </div>
                        {t("accueil.findUsAddress")}
                    </a>
                </div> */}

                <div className={styles.diagnostic_section}>
                    <div className={styles.left_column}>
                        <div className={styles.the}>{t("accueil.red.le")}</div>
                        <div className={styles.diag}>{t("accueil.red.diag")}</div>
                        <div className={styles.nostic}>{t("accueil.red.nostic")}</div>
                        <img src={oeil} alt="oeil" />
                    </div>

                    <div className={styles.right_column}>
                        {t("accueil.red.milieux")} {t("accueil.red.sont")} {t("accueil.red.problemes")} {t("accueil.red.sante")} {t("accueil.red.depiste")}
                        <br/>
                        <br/>
                        {t("accueil.red.nefaste")} {t("accueil.red.de")} {t("accueil.red.absence")} {t("accueil.red.petits")} {t("accueil.red.nuise")} {t("accueil.red.epanouisse")} {t("accueil.red.represnte")} {t("accueil.red.cout")}
                    </div>
                </div>

                <div className={styles.stats_section}>
                    <div className={`${styles.stat_holder} ${styles.orange_text}`}>
                        <h3>{t("accueil.stats.stat1Header")}</h3>
                        <p>{t("accueil.stats.stat1")}<sup>1</sup>.</p>
                    </div>
                    <div className={styles.separator}/>
                    <div className={`${styles.stat_holder} ${styles.blue_text}`}>
                        <h3>{t("accueil.stats.stat2Header")}</h3>
                        <p>{t("accueil.stats.stat2")}<sup>2</sup>.</p>
                    </div>
                    <div className={styles.separator}/>
                    <div className={`${styles.stat_holder} ${styles.purple_text}`}>
                        <h3>{t("accueil.stats.stat3Header")}</h3>
                        <p>{t("accueil.stats.stat3")}<sup>3</sup>.</p>
                    </div>
                    <p className={styles.sources}>
                        Sources : <strong>1 </strong> {t("accueil.stats.source1")}<br />
                        <strong>2 </strong> {t("accueil.stats.source3")}<br />
                        <strong>3 </strong> {t("accueil.stats.source2")}
                    </p>
                </div>

                <div className={styles.description_section}>
                    <p>
                        <img src={doctoctoc} className={styles.logo}/>{t("accueil.approche.paragraph_one")} <br/> {t("accueil.approche.paragraph_two")} <br/> {t("accueil.approche.paragraph_three")}
                    </p>
                    <Marquee className={styles.home_marquee}>
                        <div className={styles.marquee_content}>
                            {t("accueil.approche.marquee")} 
                            <img src={pngBus} className={styles.bus_img} alt="bus doctoctoc" /> 
                        </div>
                    </Marquee>
                </div>

                <div className={styles.innovation_section}>
                    <div className={styles.left_column}>
                        <img src={gears} alt="gears" />
                        <div className={styles.one}>{t("accueil.purple.une")}</div>
                        <div className={styles.innovation}>{t("accueil.purple.innovation")}</div>
                        <div className={styles.social}>{t("accueil.purple.sociale")}</div>
                    </div>

                    <div className={styles.right_column}>
                        {t("accueil.purple.clinique")} {t("accueil.purple.camion")} {t("accueil.purple.soins")} {t("accueil.purple.ligne")} 
                        <br/>
                        <br/>
                        {t("accueil.purple.proximite")} {t("accueil.purple.capable")} {t("accueil.purple.vise")} {t("accueil.purple.famille")} 
                        <br/>
                        <br/>
                        <strong>DocTocToc </strong> {t("accueil.purple.oeuvre")} {t("accueil.purple.faveur")} {t("accueil.purple.egalite")} {t("accueil.purple.chances")}
                    </div>
                </div>

                <div className={styles.partner_section}>
                    <h3>{t("accueil.blue.partenaires")}</h3>
                    <p>
                        <strong>DocTocToc </strong>
                        {t("accueil.blue.creation")} {t("accueil.blue.acteurs")} {t("accueil.blue.organismes")}
                        <br/><br/>
                        {t("accueil.blue.mission")} {t("accueil.blue.entreprise")} {t("accueil.blue.organisme")} {t("accueil.blue.individu")} {t("accueil.blue.main")}
                    </p>
                    <Link className={styles.partner_button} to={`/${intl.locale}/devenez-partenaire`}>{t("accueil.blue.devenons")}</Link>
                </div>

            </div> 

            <div className={styles.team_section}>
                <div className={styles.title}>
                    <span className={styles.team}>{t("accueil.equipe.equipe")}</span>
                    <span className={styles.direction} style={{top: intl.locale === "en" ? 80 : 15}}>{t("accueil.equipe.direction")}</span>
                </div>
                <div className={styles.teamate_holder}>
                    <img src={rislaine}/>
                    <h5>{t("accueil.equipe.rislaine.name")}</h5>
                    <p>{t("accueil.equipe.rislaine.role")}</p>
                    <p>{t("accueil.equipe.rislaine.description")}</p>
                </div>
                <div className={styles.teamate_holder}>
                    <img src={couture}/>
                    <h5>{t("accueil.equipe.couture.name")}</h5>
                    <p>{t("accueil.equipe.couture.role")}</p>
                    <p>{t("accueil.equipe.couture.description")}</p>
                </div>
            </div>

            <Footer color="#f04e23"/>

        </Layout>
    )
}

export default HomePage